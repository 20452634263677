export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboards',
    },
    {
      category: true,
      title: 'Prenómina',
    },
    {
      title: 'Prenómina',
      key: 'prenomina',
      icon: 'fe fe-database',
      url: '/prenomina',
    },
  ];
}
