import localeAntd from 'antd/es/locale/es_ES';

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  Rechazar: 'Rechazar',
  'Aprobar con comentario': 'Aprobar con comentario',
  Aprobar: 'Aprobar',
  'Se ha importado el archivo correctamente': 'Se ha importado el archivo correctamente',
  'Creación de prenómina': 'Creación de prenómina',
  'Hubo un error inesperado': 'Hubo un error inesperado',
  'Ha ocurrido un error al guardar la prenómina': 'Ha ocurrido un error al guardar la prenómina',
  closedPeriodDescription: 'El periodo está Cerrado, no es posible volverlo a modificar. Contacta al encargado de abrir periodos cerrados.',
  fileImportCorrectMessage: 'Se ha importado el archivo correctamente',
  fileImportErrorMessage: 'No se ha podido importar el archivo. Contacta a tu ADC asignado para resolver el problema',
  errorMsjMessage: 'La prenómina se encuentra rechazada y pronto se te enviará una nueva',
  successMsjMessage: 'La prenómina se encuentra aprobado',
  warningMsjMessage: 'La prenómina se encuentra en espera de su aprobación',
  client: 'Cliente',
  exportExcel: 'Exportar Excel',
  importExcel: 'Importar Excel',
  totalBilling: 'Total Factura',
  totalBillingInput: 'Captura el monto total',
  payroll: 'Nómina',
  period: 'Periodo',
  prepayrollTableOrderMessage: 'Puedes ordenar tu prenómina por Clave, Nombre, Apellido Paterno o Apellido Materno, solamente da click en la columna',
  key: 'Clave',
  name: 'Nombre',
  firstLastName: 'A. Paterno',
  secondLastName: 'A. Materno',
  paymentMethod: 'Método Pago',
  bank: 'Banco',
  totalAmount: 'Importe a Pagar',
  electronicFundsTransfer: 'Transferencia electrónica de fondos',
  electronicMoney: 'Dinero electrónico',
  creditCard: 'Tarjeta de crédito',
  cash: 'Efectivo',
  serviceType: 'Tarjeta de servicio',
  electronicWallet: 'Monedero electrónico',
  debitCard: 'Tarjeta de débito',
  others: 'Otros',
  groceryVouchers: 'Vales de despensa',
  nominalCheck: 'Cheque nominativo',
  vacationDays: 'Días Vacaciones',
  holidayPremiumDays: 'Días de Prima Vacacional',
  faults: 'Faltas',
  inability: 'Incapacidad',
  workedHolidays: 'Días Festivos Trabajados',
  breaksWorked: 'Descansos Trabajados',
  paidLeave: 'Permiso c/Sueldo',
  unpaidLeave: 'Permiso s/Sueldo',
  sundayPremiumDays: 'Días de Prima Dominical',
  attendanceAward: 'Premio de Asistencia',
  punctualityAward: 'Premio de Puntualidad',
  comissions: 'Comisiones',
  gratification: 'Gratificación',
  retroactiveSalaries: 'Sueldos Retroactivos',
  discounts: 'Descuentos',
  otherDeductions: 'Otras Deducciones',
  randomBonuses: 'Bonos Varios',
  savingsFundAmountClient: 'Cantidad de Fondo de Ahorro (Cliente)',
  savingsFundAmountEmployee: 'Cantidad de Fondo de Ahorro (Empleado)',
  savingsFundType: 'Tipo de Fondo de Ahorro',
  actualSalary: 'Sueldo Real',
  finalBonusAmount: 'Monto Aguinaldo Final',
  bonusDays: 'Dias de Aguinaldo',
  incorporationDate: 'Fecha Incorporacion',
  doubleOvertime: 'T. Extra Doble',
  tripleOvertime: 'T. Extra Triple',
  tenureDate: 'Fecha de antiguedad',
  descripcionModalRechazar: 'Por favor indicanos la razón por la cuál rechazas el cálculo de la nómina',
  okTextRechazo: 'Enviar razón del rechazo',
  titleModalAprobadoPeriodo: 'Aprobar prenómina',

  payrollSummaryTitle: 'Resumen del cálculo de nómina',
  buttonTxtRechazar: 'Rechazar',
  buttonTxtAprobar: 'Aprobar',
  okTextAprobado: 'Adjuntar recibo de pago',
  payrollSummaryAlertSuccessMsg: 'El cálculo de la nómina se encuentra aprobado',
  payrollSummaryAlertErrorMsg: 'El cálculo de la nómina se encuentra rechazado y pronto se te enviará un nuevo cálculo',
  payrollsummaryAlertWarningMsg: 'El cálculo de la nómina se encuentra en espera de su aprobación',
  payrollSummaryDataError: 'Hubo un error al pedir la informacion',
  payrollSummaryTabOneTxt: 'Resumen de nómina y factura',
  companySummary: 'Resumen de empresa',
  payrollSrummaryTabLastTxt: 'Resumen de empleados',
  'Resumen Nómina': 'Resumen Nómina',
  'Resumen Facturación': 'Resumen Facturación',
  Empresa: 'Empresa',
  'Total a dispersar': 'Total a dispersar',
  'Costo social': 'Costo social',
  Comisión: 'Comisión',
  'Cobros pendientes': 'Cobros pendientes',
  Bonificaciones: 'Bonificaciones',
  Provisiones: 'Provisiones',
  'Comisión Provisiones': 'Comisión Provisiones',
  'Total a facturar': 'Total a facturar',
  'Empresa NOM035': 'Empresa NOM035',
  Cliente: 'Cliente',
  'Tipo de pago': 'Tipo de pago',
  'Nombre de la nómina': 'Nombre de la nómina',
  'Fecha de inicio del periodo': 'Fecha de inicio del periodo',
  'Fecha fin del periodo': 'Fecha fin del periodo',
  'Clave del periodo': 'Clave del periodo',
  Nominista: 'Nominista',
  'Fecha dispersión': 'Fecha dispersión',
  Concepto: 'Concepto',
  Porcentaje: 'Porcentaje',
  'Giro de Servicio': 'Giro de Servicio',
  'Subtotal Giro': 'Subtotal Giro',
  '(+) IVA Giro 16%': '(+) IVA Giro 16%',
  'Total Giro': 'Total Giro {idx}',
  Clave: 'Clave',
  Nombre: 'Nombre',
  'A. Paterno': 'A. Paterno',
  'A. Materno': 'A. Materno',
  'Total a pagar': 'Total a pagar',
  'Total percepciones': 'Total percepciones',
  'Total deducciones': 'Total deducciones',
  'SYS a dispersar': 'SYS a dispersar',
  'PPPS a dispersar': 'PPPS a dispersar',
  'Método de pago': 'Método de pago',
  Banco: 'Banco',
  Cuenta: 'Cuenta',
  'Base cotización': 'Base cotización',
  titleModalRechazo: '¿Cuál es la razón del rechazo?',
  titleModalAprobado: 'Adjuntar comprobante',
  comedor: 'Comedor',
  descripcionModalAprobar: 'Por favor adjunta el/los comprobante(s) de la transferencia o depósito, puede ser una imagen o un pdf o inclusive una foto del ticket',
  'Oh no! La prenómina ya no existe o nunca existió. Verifica que tienes el URL correcto': 'Oh no! La prenómina ya no existe o nunca existió. Verifica que tienes el URL correcto',
  'Oh no! Este link ya no es válido. Verifica que tienes el URL correcto': 'Oh no! Este link ya no es válido. Verifica que tienes el URL correcto',
  'Oh no! No se han encontrado prenóminas para llenar en este periodo. Solicita ayuda a tu responsable de nómina.': 'Oh no! No se han encontrado prenóminas para llenar en este periodo. Solicita ayuda a tu responsable de nómina.',
  department: 'Departamento',
  fiscalPayment: 'Pagar Fiscal',
  v2ApproveYourPrePayroll: 'Aprueba tu Prenómina',
  v2LoadingYourPrePayroll: 'Cargando tu prenómina...',
  v2ErrorWhileLoadingPage: 'Ha ocurrido un problema al cargar esta pagina!',
  v2VerifyCorrectURL: 'Verifica que tengas el URL correcto',
  v2Approve: 'Aprobar',
  v2Reject: 'Rechazar',
  v2ExportExcel: 'Exportar Excel de Prenómina',
  v2ImportExcel: 'Importar Excel de Prenómina',
  v2PayrollType: 'Tipo de Nómina',
  v2Payroll: 'Nómina',
  v2PeriodRange: 'Periodo de Calculo',
  v2AlertPrePayrollNoLongerAvailable: 'La prenómina de este link ya no puede ser aprobada, debido a que ya se ha continuado el proceso internamente',
  v2PrePayrollWaitingForApproval: 'Tu prenómina esta en espera de aprobación',
  v2PrePayrollIsMeantForExport: 'Esta prenómina debe ser exportada, importada y revisada contra cambios hechos con validaciones para ser aprobada. Utilice los botones de arriba para exportar o importar y luego rechazar o aprobar',
  v2PrePayrollApproved: 'La prenómina ya ha sido aprobada! Lo que ves a continuación es un resumen de la información que se utilizara para el calculo',
  v2PrePayrollRejected: 'La prenómina ya ha sido rechazada! En breve se pondran en contacto contigo para verificar tu prenómina...',
  v2QueryPlaceholder: 'Busqueda de empleados por nombre...',
  v2ApproveConfirmationTitle: '¿Estas seguro de aprobar tu prenómina',
  v2ApproveConfirmationDescription: 'Una vez enviada, ya no podras editarla a menos de que contactes al equipo de la plataforma.',
  v2PayrollApproved: 'Se ha aprobado tu prenómina y se ha notificado a la plataforma',
  v2PayrollApprovedError: 'Ha ocurrido un al aprobar tu prenómina. Favor de contactar al equipo de la plataforma [C2]',
  v2PayrollRejected: 'Se ha rechazado tu prenómina y se ha notificado a la plataforma',
  v2PayrollRejectedError: 'Ha ocurrido un error al rechazar la prenómina. Favor de contactar al equipo de la plataforma [C2]',
  v2RejectConfirmationTitle: '¿Estas seguro de rechazar tu prenómina',
  v2RejectConfirmationDescription: 'Una vez rechazada, el equipo de la plataforma se pondra en contacto contigo.',
  v2TextAreaConfirmationPlaceholder: '(Opcional) Comentario para el equipo de la plataforma... (Maximo 10,000 caracteres)',
  v2BonusUpdateFieldCalculationError: 'Ha ocurrido un error al calcular la información del empleado con el nuevo cambio. Por favor reporta esto al equipo de la plataforma',
  v2PrePayrollsHaveIncidencesWarning: 'Uno o mas empleados tienen incapacidades... ¿Estas seguro que esto es correcto?',
  v2BonusAmountAdjustmentNotification: 'Se ha ajustado el salario diario real del empleado como minimo al proporcional fiscal',
  v2BonusDisabilityDaysCantChangeOtherThan0OrDefault: 'Solo tienes permiso cambio el numero de dias de incapacidad a 0 o el valor predeterminado que tenia el empleado',
  v2BonusImportUpdateFieldActualSalaryIsFiscal: 'El empleado {employeeName} se ha marcado como "Pago Fiscal", ya que el Salario Diario Real ({realDailySalary}) cayó por debajo del Salario Diario Fiscal ({fiscalDailySalary}). Si este cambio no es deseado, verifica la información del empleado en tu Excel.',
  v2ImportExcelError: 'Algo paso al tratar de subir tu archivo! Por favor intenta de nuevo. Si el error persiste, por favor contacta a la plataforma',
  v2ImportExcelSuccess: 'Tu archivo se ha cargado, por favor revisa los empleados por cambios que se hayan realizado por validaciones.',
  v2ImportUpdatesHappened: 'Algunos de tus empleados fueron actualizados gracias a algunas de nuestras validaciones, por favor revisa los cambios...',
  v2ImportNoUpdates: 'Tus empleados no sufrieron ningun cambio por nuestras validaciones, puedes proceder a aprobar o rechazar',
  v2ImportUpdatesReviewTitle: 'Actualizaciones Automaticas de Prenómina',
  v2ImportUpdatesReviewDescription: 'Los siguientes cambios han sido aplicado a tus empleados!',
  v2SeeUpdates: 'Revisar Cambios',
  v2GridHeaderFiscal: '¿Es Pago Fiscal?',
  v2GridHeaderFullName: 'Nombre Completo',
  v2GridHeaderPaymentMethod: 'Metodo de Pago',
  v2GridHeaderBonusAmount: 'Sueldo Diario Real (Complemento)',
  v2GridHeaderFaults: 'Faltas',
  v2GridHeaderDisabilitiesDays: 'Incapacidad',
  v2GridHeaderBonusDaysFondo: 'Dias de Aguinaldo Real (Complemento)',
  v2GridHeaderBonusAmountFondo: 'Monto de Aguinaldo Real (Complemento)',
  v2GridHeaderBonusDaysFiscal: 'Dias de Aguinaldo Fiscal',
  v2GridHeaderBonusAmountFiscal: 'Monto de Aguinaldo Fiscal',
  v2GridHeaderBonusDaysBase: 'Dias Base de Aguinaldo',
  v2GridHeaderIncorporationDate: 'Fecha de Incorporación (DD/MM/YYYY)',
  v2GridHeaderTenureDate: 'Fecha de Antiguedad (DD/MM/YYYY)',
  v2FiscalSum: 'Monto Total Fiscal',
  v2RealSum: 'Monto Total Complemento',
  v2TotalSum: 'Monto Total',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  v2GridHeaderDepartment: 'Departamento',
  v2GridHeaderKey: 'Clave',
  v2GridHeaderSocietyBranch: 'Sucursal',
  v2GridHeaderPayrollType: 'Nómina',
  v2GridHeaderBankAccount: 'Cuenta de Banco',
  v2GridHeaderAmount: 'Importe',
  v2GridHeaderVacation: 'Dias de Vacaciones',
  v2GridHeaderVacationPremium: 'Dias de Prima Vacacional',
  v2GridHeaderSundayPremium: 'Dias de Prima Dominical',
  v2GridHeaderAttendanceAward: 'Premio de Asistencia',
  v2GridHeaderPunctualityAward: 'Premio de Puntualidad',
  v2GridHeaderComission: 'Comisiones',
  v2GridHeaderGratification: 'Gratificación',
  v2GridHeaderRetroActiveSalary: 'Sueldo Retroactivo',
  v2GridHeaderDiscounts: 'Descuentos',
  v2GridHeaderComedor: 'Comedor',
  v2GridHeaderOtherDeductions: 'Otras Deducciones',
  v2GridHeaderBonosVarios: 'Bonos Varios',
  v2GridHeaderSavingFundsClient: 'Fondo de Ahorro (Cliente)',
  v2GridHeaderSavingFundsEmployee: 'Fondo de Ahorro (Empleado)',
  v2GridHeaderSavingFundsType: 'Tipo de Fondo de Ahorro',
  v2GridHeaderDisabilityDays: 'Incapacidades',
  v2GridHeaderDoubleOvertime: 'Tiempo Extra Doble',
  v2GridHeaderTripleOvertime: 'Tiempo Extra Triple',
  v2GridHeaderHolidayWorked: 'Dias Festivos Trabajados',
  v2GridHeaderRestWorked: 'Descansos Trabajados',
  v2GridHeaderFaltaConGoce: 'Permiso con Sueldo',
  v2GridHeaderFaltaSinGoce: 'Permiso sin Sueldo',
  v2GridHeaderFirstLastName: 'A. Paterno',
  v2GridHeaderSecondLastName: 'A. Materno',
  v2GridHeaderName: 'Nombre',
  v2GridHeaderAhorroUniformes: 'Ahorro Uniformes',
  v2GridHeaderDeduccionesNOM: 'Deducciones',
  v2GridHeaderBonoProductividad: 'Bono Productividad',
  v2ShowFilters: 'Mostrar Filtros',
  v2HideFilters: 'Ocultar Filtros',
  v2SortOptionEmployeeKey: 'Clave',
  v2SortOptionEmployeeName: 'Nombre',
  v2SortOptionEmployeeFirstLastName: 'A. Paterno',
  v2SortOptionEmployeeSecondLastName: 'A. Materno',
};

export default {
  locale: 'es-MX',
  localeAntd,
  messages,
};
