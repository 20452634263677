import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import Layout from 'layouts';

const routes = [
  // Dashboards
  {
    path: '/periodo/:id/:hash',
    Component: lazy(() => import('pages/prenomina/prenomina')),
    exact: true,
  },
  {
    path: '/prepayroll/:payrollPeriodId/:authHash',
    Component: lazy(() => import('pages/prepayroll')),
    exact: true,
  },
  {
    path: '/resumen-nomina/:id/:hash',
    Component: lazy(() => import('pages/resumenNomina')),
    exact: true,
  },
  {
    path: '/kardexClient/:id/:hash',
    Component: lazy(() => import('pages/kardexClient')),
    exact: true,
  },
  {
    path: '/propuestas/:id/:proposalID/:hash',
    Component: lazy(() => import('pages/proposals')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
});

const Router = ({ history, routerAnimation }) => (
  <ConnectedRouter history={history}>
    <Layout>
      <Route
        render={(state) => {
          const { location } = state;
          return (
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                appear
                classNames={routerAnimation}
                timeout={routerAnimation === 'none' ? 0 : 300}
              >
                <Switch location={location}>
                  <Route exact path="/" render={() => <Redirect to="/auth/404" />} />
                  {routes.map(({ path, Component, exact }) => (
                    <Route
                      path={path}
                      key={path}
                      exact={exact}
                      render={() => (
                        <div className={routerAnimation}>
                          <Suspense fallback={null}>
                            <Component />
                          </Suspense>
                        </div>
                      )}
                    />
                  ))}
                  <Redirect to="/auth/404" />
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          );
        }}
      />
    </Layout>
  </ConnectedRouter>
);

export default connect(mapStateToProps)(Router);
