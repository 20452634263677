import localeAntd from 'antd/es/locale/en_US';

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  Rechazar: 'Reject',
  'Aprobar con comentario': 'Approve with comments',
  Aprobar: 'Approve',
  'Se ha importado el archivo correctamente': 'The file was imported successfully',
  'Creación de prenómina': 'Prepayroll Creation',
  'Hubo un error inesperado': 'There was an unexpected error',
  'Ha ocurrido un error al guardar la prenómina': 'There was an error saving the prepayroll',
  closedPeriodDescription: 'The perido is closed, modification its not possible. Contact the person in charge to open periods',
  fileImportCorrectMessage: 'The file was imported correctly',
  fileImportErrorMessage: 'The file couldnt be imported. Contact the assigned ADC to solve the problem',
  errorMsjMessage: 'The prepayroll is rejectes and a new one will be sent soon',
  successMsjMessage: 'The prepayroll is approved',
  warningMsjMessage: 'The prepayroll is awaiting approval',
  client: 'Client',
  exportExcel: 'Export Excel',
  importExcel: 'Import Excel',
  totalBilling: 'Total Billing',
  totalBillingInput: 'Enter the final amount',
  payroll: 'Payroll',
  period: 'Period',
  prepayrollTableOrderMessage: 'The table can be order by key, name, first name or lastname by clicking on the column',
  key: 'Key',
  name: 'Name',
  firstLastName: 'First Lastname',
  secondLastName: 'Second Lastname',
  paymentMethod: 'Payment Method',
  bank: 'Bank',
  totalAmount: 'Total Amount',
  electronicFundsTransfer: 'Electronic Funds Transfer',
  electronicMoney: 'Electronic Money',
  creditCard: 'Credit Card',
  cash: 'Cash',
  serviceType: 'Service Type',
  electronicWallet: 'Electronic Wallet',
  debitCard: 'Debit Card',
  others: 'Others',
  groceryVouchers: 'Grocery Vouchers',
  nominalCheck: 'Nominal Check',
  vacationDays: 'Vacation Days',
  holidayPremiumDays: 'Holiday Premium Days',
  faults: 'Faults',
  inability: 'Inability',
  workedHolidays: 'Worked Holidays',
  breaksWorked: 'Breaks Worked',
  paidLeave: 'Paid Leave',
  unpaidLeave: 'Unpaid Leave',
  sundayPremiumDays: 'Sunday Premium Days',
  attendanceAward: 'Attendance Award',
  punctualityAward: 'Punctuality Award',
  comissions: 'Comissions',
  gratification: 'Gratification',
  retroactiveSalaries: 'Retroactive Salaries',
  discounts: 'Discounts',
  otherDeductions: 'Other deductions',
  randomBonuses: 'Random Bonuses',
  savingsFundAmountClient: 'Savings Fund Amount (Client)',
  savingsFundAmountEmployee: 'Savings Fund Amount (Employee)',
  savingsFundType: 'Savings Fund Type',
  actualSalary: 'Actual Salary',
  finalBonusAmount: 'Final Bonus Amount',
  bonusDays: 'Bonus Days',
  incorporationDate: 'Incorporation Date',
  doubleOvertime: 'Double Overtime',
  tripleOvertime: 'Triple Overtime',
  tenureDate: 'Tenure Date',
  descripcionModalRechazar: 'Please let us know the reason why you are rejecting the payroll calculation',
  okTextRechazo: 'Send reject reason',
  titleModalAprobadoPeriodo: 'Approve prepayroll',

  payrollSummaryTitle: 'Payroll Calculation Summary',
  buttonTxtRechazar: 'Reject',
  buttonTxtAprobar: 'Approve',
  okTextAprobado: 'Attach payment receipt',
  payrollSummaryAlertSuccessMsg: 'The payroll calculation is calculated',
  payrollSummaryAlertErrorMsg: 'The payroll calculation is rejects and a new calculation will be sent soon.',
  payrollsummaryAlertWarningMsg: 'Payroll calculation is awaiting your approval',
  payrollSummaryDataError: 'There was a problem getting the information',
  payrollSummaryTabOneTxt: 'Payroll and billing summary',
  companySummary: 'Company summary',
  payrollSrummaryTabLastTxt: 'Employees Summary',
  'Resumen Nómina': 'Payroll Summary',
  'Resumen Facturación': 'Billing Summary',
  Empresa: 'Company',
  'Total a dispersar': 'Total to disperse',
  'Costo social': 'Social cost',
  Comisión: 'Commission',
  'Cobros pendientes': 'Pending charges',
  Bonificaciones: 'Bonifications',
  Provisiones: 'Provisions',
  'Comisión Provisiones': 'Commission Provisions',
  'Total a facturar': 'Total to invoice',
  'Empresa NOM035': 'NOM035 Company',
  Cliente: 'Client',
  'Tipo de pago': 'Payment method',
  'Nombre de la nómina': 'Payroll name',
  'Fecha de inicio del periodo': 'Period start date',
  'Fecha fin del periodo': 'Period end date',
  'Clave del periodo': 'Period key',
  Nominista: 'Nominist',
  'Fecha dispersión': 'Dispersion date',
  Concepto: 'Concept',
  Porcentaje: 'Percentage',
  'Giro de Servicio': 'Business line type',
  'Subtotal Giro': 'Business line subtotal',
  '(+) IVA Giro 16%': '(+) Business line IVA 16%',
  'Total Giro': 'Business line {idx}',
  Clave: 'Key',
  Nombre: 'Name',
  'A. Paterno': 'First lastname',
  'A. Materno': 'Second lastname',
  'Total a pagar': 'Total to pay',
  'Total percepciones': 'Total perceptions',
  'Total deducciones': 'Total deductions',
  'SYS a dispersar': 'SYS to disperse',
  'PPPS a dispersar': 'PPPS to disperse',
  'Método de pago': 'Payment method',
  Banco: 'Bank',
  Cuenta: 'Account',
  comedor: 'Dining Fee',
  'Base cotización': 'Quotation base',
  titleModalRechazo: 'What is the reason for rejection?',
  titleModalAprobado: 'Attach voucher',
  descripcionModalAprobar: 'Please attach the proof(s) of the transfer or deposit, it can be an image or a pdf or even a photo of the ticket',
  'Oh no! La prenómina ya no existe o nunca existió. Verifica que tienes el URL correcto': 'Oh no! The prepayroll no longer exists or never existed. Verify that you have the correct URL',
  'Oh no! Este link ya no es válido. Verifica que tienes el URL correcto': 'Oh no! This link is no longer valid. Verify that you have the correct URL',
  'Oh no! No se han encontrado prenóminas para llenar en este periodo. Solicita ayuda a tu responsable de nómina.': 'No prepayroll have been found to fill in this period. Ask your payroll responsible for help.',
  department: 'Department',
  fiscalPayment: 'Pay only Fiscal amount',
  v2ApproveYourPrePayroll: 'Approve Your Pre-Payroll',
  v2LoadingYourPrePayroll: 'Loading your pre-payroll...',
  v2ErrorWhileLoadingPage: 'An error occurred while loading this page!',
  v2VerifyCorrectURL: 'Verify that you have the correct URL',
  v2Approve: 'Approve',
  v2Reject: 'Reject',
  v2ExportExcel: 'Export Pre-Payroll Excel',
  v2ImportExcel: 'Import Pre-Payroll Excel',
  v2PayrollType: 'Payroll Type',
  v2Payroll: 'Payroll',
  v2PeriodRange: 'Calculation Period',
  v2AlertPrePayrollNoLongerAvailable: 'The pre-payroll from this link can no longer be approved, as the internal process has already continued',
  v2PrePayrollWaitingForApproval: 'Your pre-payroll is awaiting approval',
  v2PrePayrollApproved: 'The pre-payroll has already been approved! What you see next is a summary of the information that will be used for the calculation',
  v2PrePayrollRejected: 'The pre-payroll has already been rejected! You will be contacted shortly to verify your pre-payroll...',
  v2PrePayrollIsMeantForExport: 'This pre-payroll is meant to be exported, imported and reviewed for changes done through validations in order to be approved. Use the buttons above to rather export or import, and then reject or approve',
  v2QueryPlaceholder: 'Employee search by name...',
  v2ApproveConfirmationTitle: 'Are you sure you want to approve your pre-payroll',
  v2ApproveConfirmationDescription: 'Once submitted, you won\'t be able to edit it unless you contact the team.',
  v2PayrollApproved: 'Your pre-payroll has been approved and the team has been notified',
  v2PayrollApprovedError: 'An error occurred while approving your pre-payroll. Please contact the team [C2]',
  v2PayrollRejected: 'Your pre-payroll has been rejected and the team has been notified',
  v2PayrollRejectedError: 'An error occurred while rejecting the pre-payroll. Please contact the team [C2]',
  v2RejectConfirmationTitle: 'Are you sure you want to reject your pre-payroll',
  v2RejectConfirmationDescription: 'Once rejected, the team will contact you.',
  v2TextAreaConfirmationPlaceholder: '(Optional) Comment for the team... (Maximum 10,000 characters)',
  v2BonusUpdateFieldCalculationError: 'An error occurred while calculating the employee information with the new change. Please report this to the team',
  v2BonusDisabilityDaysCantChangeOtherThan0OrDefault: 'You\'re only allowed to change the value of disability days to 0 or the default value that the employee had',
  v2PrePayrollsHaveIncidencesWarning: 'One or more employees have incidences... Are you sure this is intended?',
  v2BonusAmountAdjustmentNotification: 'An adjustment to the Real Daily Salary of the employee has been applied to match atleast the fiscal amount',
  v2BonusImportUpdateFieldActualSalaryIsFiscal: 'The employee {employeeName} has been marked as "Fiscal Payment", since the Real Daily Salary ({realDailySalary}) fell under the Fiscal Daily Salary ({fiscalDailySalary}). If this is not intended, please check your employee in the Excel file.',
  v2ImportExcelError: 'Something happened while uploading your file! Please try again. If the error persists, please contact the team',
  v2ImportExcelSuccess: 'Your file has been uploaded, please review your employees in case any changes have occured from validating.',
  v2ImportUpdatesHappened: 'Some of your employees were updated because some of our validations were triggered, please review the changes...',
  v2ImportNoUpdates: 'Your employees didn\'t suffer any change from our validations, you can safely proceed to approve or reject',
  v2ImportUpdatesReviewTitle: 'Automatic PrePayroll Updates',
  v2ImportUpdatesReviewDescription: 'The following changes were made to your employees',
  v2SeeUpdates: 'Review Changes',
  v2GridHeaderFiscal: 'Is Only Fiscal?',
  v2GridHeaderFullName: 'Full Name',
  v2GridHeaderPaymentMethod: 'Payment Method',
  v2GridHeaderBonusAmount: 'Real Daily Salary (Pension)',
  v2GridHeaderFaults: 'Absences',
  v2GridHeaderDisabilitiesDays: 'Disability Days',
  v2GridHeaderBonusDaysFondo: 'Real Bonus Days (Pension)',
  v2GridHeaderBonusAmountFondo: 'Real Bonus Amount (Pension)',
  v2GridHeaderBonusDaysFiscal: 'Fiscal Bonus Days',
  v2GridHeaderBonusAmountFiscal: 'Fiscal Bonus Amount',
  v2GridHeaderBonusDaysBase: 'Base Bonus Days',
  v2GridHeaderIncorporationDate: 'Incorporation Date (DD/MM/YYYY)',
  v2GridHeaderTenureDate: 'Tenure Date (DD/MM/YYYY)',
  v2FiscalSum: 'Total Sum Fiscal',
  v2RealSum: 'Total Sum Real',
  v2TotalSum: 'Total Sum',
  confirm: 'Confirm',
  cancel: 'Cancel',
  v2GridHeaderDepartment: 'Department',
  v2GridHeaderSocietyBranch: 'Branch',
  v2GridHeaderPayrollType: 'Payroll',
  v2GridHeaderBankAccount: 'Bank Account',
  v2GridHeaderAmount: 'Amount',
  v2GridHeaderKey: 'Key',
  v2GridHeaderVacation: 'Vacation Days',
  v2GridHeaderVacationPremium: 'Vacation Premium Days',
  v2GridHeaderSundayPremium: 'Sunday Premium Days',
  v2GridHeaderAttendanceAward: 'Attendance Award',
  v2GridHeaderPunctualityAward: 'Punctuality Award',
  v2GridHeaderComission: 'Comission',
  v2GridHeaderGratification: 'Gratification',
  v2GridHeaderRetroActiveSalary: 'Retroactive Salary',
  v2GridHeaderDiscounts: 'Discounts',
  v2GridHeaderComedor: 'Dining Fee',
  v2GridHeaderOtherDeductions: 'Other Deductions',
  v2GridHeaderBonosVarios: 'Random Bonuses',
  v2GridHeaderSavingFundsClient: 'Savings Fund (Cliente)',
  v2GridHeaderSavingFundsEmployee: 'Savings Fund (Empleado)',
  v2GridHeaderSavingFundsType: 'Type of Savings Fund',
  v2GridHeaderDisabilityDays: 'Disability Days',
  v2GridHeaderDoubleOvertime: 'Double Overtime',
  v2GridHeaderTripleOvertime: 'Triple Overtime',
  v2GridHeaderHolidayWorked: 'Holidays Worked',
  v2GridHeaderRestWorked: 'Breaks Worked',
  v2GridHeaderFaltaConGoce: 'Paid Leave',
  v2GridHeaderFaltaSinGoce: 'Unpaid Leave',
  v2GridHeaderFirstLastName: 'F. Lastname',
  v2GridHeaderSecondLastName: 'S. Lastname',
  v2GridHeaderName: 'Name',
  v2GridHeaderAhorroUniformes: 'Uniform Savings',
  v2GridHeaderDeduccionesNOM: 'Deductions',
  v2GridHeaderBonoProductividad: 'Productivity Bonus',
  v2ShowFilters: 'Show Filters',
  v2HideFilters: 'Hide Filters',
  v2SortOptionEmployeeKey: 'Key',
  v2SortOptionEmployeeName: 'Name',
  v2SortOptionEmployeeFirstLastName: 'First Lastname',
  v2SortOptionEmployeeSecondLastName: 'Second Lastname',
};

export default {
  locale: 'en-US',
  localeAntd,
  messages,
};
