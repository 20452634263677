import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import style from './style.module.scss';

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
});

const AuthLayout = ({
  children,
}) => (
  <Layout>
    <Layout.Content>
      <div className={style.containerInner}>{children}</div>
    </Layout.Content>
  </Layout>
);

export default withRouter(connect(mapStateToProps)(AuthLayout));
